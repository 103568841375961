import VueLib from 'vue'
import merge from 'lodash.mergewith'
import * as Sentry from '@sentry/browser'
import { Dedupe, ExtraErrorData, ReportingObserver, RewriteFrames, Vue } from '@sentry/integrations'

export default function (ctx, inject) {
  /* eslint-disable object-curly-spacing, quote-props, quotes, key-spacing, comma-spacing */
  const config = {
    dsn:"needed-by-buildscript",
    environment:"production",
    beforeSend:function(event, hint) {
        // Sentry calls `toString()` on everything that's passed into contexts automatically
        // this results in `[object Object]` in graphQLErrors
        // solution seems to be to replace those strings with the objects from the original exception here
        const getNested = (object, path) =>
          path.split('.').reduce((o, x) => (!o ? o : o[x]), object)

        if (
          getNested(event, 'contexts.Error.graphQLErrors') &&
          getNested(hint, 'originalException.graphQLErrors')
        ) {
          event.contexts.Error.graphQLErrors =
            hint.originalException.graphQLErrors
        }
        return event
      },
    release:"fc256291a08a1eea562fd7c98b561625ec62e333"
  }

  const runtimeConfigKey = "sentry"
  if (ctx.$config && runtimeConfigKey && ctx.$config[runtimeConfigKey]) {
    merge(config, ctx.$config[runtimeConfigKey].config, ctx.$config[runtimeConfigKey].clientConfig)
  }

  config.integrations = [
    new Dedupe(),
    new ExtraErrorData(),
    new ReportingObserver(),
    new RewriteFrames(),
    new Vue({ Vue: VueLib, ...{"attachProps":true,"logErrors":false}})
  ]

  /* eslint-enable object-curly-spacing, quote-props, quotes, key-spacing, comma-spacing */
  Sentry.init(config)

  inject('sentry', Sentry)
  ctx.$sentry = Sentry
}
